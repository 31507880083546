<template>
  <div
    :id="data.lineTxt.toLowerCase()"
    :class="['box', data.traffic.delays.class || 'status-wip']"
  >
    <header :class="`bg-${data.lineTxt.toLowerCase()}`">
      <img
        v-if="
          data.traffic.delays.class == 'status-out-of-service' ||
          data.traffic.delays.class == 'status-wip'
        "
        :alt="data.lineTxt"
        :src="`./signets/${data.lineTxt.toLowerCase()}_grau.svg`"
      />
      <img
        v-else
        :alt="data.lineTxt"
        :src="`./signets/${data.lineTxt.toLowerCase()}.svg`"
      />
    </header>

    <div>
      <WipFlatterband v-if="data.hasDisruption" />
    </div>

    <main>
      <TabStufe0
        v-if="
          !data.traffic.delays.class ||
          data.traffic.delays.class == 'status-wip'
        "
      />
      <TabStufe1 v-if="data.traffic.delays.class == 'status-okay'" />
      <TabStufe2 v-if="data.traffic.delays.class == 'status-notice'" />
      <TabStufe3 v-if="data.traffic.delays.class == 'status-warning'" />
      <TabStufe4 v-if="data.traffic.delays.class == 'status-out-of-service'" />
    </main>
  </div>
</template>

<script>
import WipFlatterband from './WipFlatterband.vue';
import TabStufe0 from './TabStufe0.vue';
import TabStufe1 from './TabStufe1.vue';
import TabStufe2 from './TabStufe2.vue';
import TabStufe3 from './TabStufe3.vue';
import TabStufe4 from './TabStufe4.vue';

export default {
  components: {
    WipFlatterband,
    TabStufe0,
    TabStufe1,
    TabStufe2,
    TabStufe3,
    TabStufe4,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.box {
  display: grid;
  grid-template-rows: var(--box-line) var(--flatterband) calc(
      var(--box-height) - var(--box-line) - var(--flatterband)
    );
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background: #fff;
  z-index: 1;

  height: var(--box-height);
  width: var(--box-width);
}

.status-out-of-service {
  box-shadow: none;
  background: var(--step-oos-bg);
}

.status-wip {
  background: var(--step-wip-bg);
}

.status-okay {
  background: var(--step-1-bg);
}

.status-notice {
  background: var(--step-2-bg);
}

.status-warning {
  background: var(--step-3-bg);
}

header {
  display: flex;
  justify-content: center;
  text-align: center;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
